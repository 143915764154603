<template>
    <div>
        <notifications
            :notifications="$store.state.notifications.data"
            @delete="handleDelete($event)"
        />
        <vs-pagination
            class="mt-10"
            v-model="page"
            :length="$store.state.notifications.total"
        />
    </div>
</template>
<script>
import Notifications from "../../originals/notify/Notifications.vue";
export default {
    components: { Notifications },
    data() {
        return {
            page: 1,
        };
    },
    methods: {
        handleDelete($event) {
            if (confirm("Hành động không thể hoàn tác, bạn có chắc chắn"))
                this.$store.dispatch("notifications/delete", $event);
        },
    },
    watch: {
        page(page) {
            this.$store.dispatch("notifications/loadNotifications", { page });
        },
        "$store.state.notifications.loading"(isLoading) {
            if (isLoading) {
                this.loading = this.$vs.loading();
            } else if (this.loading) {
                this.loading.close();
            }
        },
    },
    beforeCreate() {
        this.$store.dispatch("notifications/loadNotifications");
    },
};
</script>