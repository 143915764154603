<template>
    <vs-table class="border" striped >
        <template #thead>
            <vs-tr>
                <vs-th>Tiêu đề</vs-th>
                <vs-th>Kênh thông báo</vs-th>
                <vs-th>Thời gian gửi</vs-th>
                <vs-th>Số lượng người nhận</vs-th>
                <vs-th>Cập nhập</vs-th>
                <vs-th>Cho phép gửi</vs-th>
                <vs-th>Xóa thông báo</vs-th>
            </vs-tr>
        </template>
        <template #tbody>
            <vs-tr v-for="(notify, i) in notifications" :key="i">
                <vs-td>
                    <router-link :to="`/admin/notifications/edit/` + notify.id">
                        <span class="text-blue-500">{{notify.title}}</span>
                    </router-link>
                </vs-td>
                <vs-td>{{notify.channel}}</vs-td>
                <vs-td>{{ moment(notify.date).format('Y-MM-DD hh:mm:ss') }}</vs-td>
                <vs-td>{{notify.is_send_all ? 'Tất cả' : notify.accounts.length}}</vs-td>
                <vs-td>{{ moment(notify.updated_at).format('Y-MM-DD hh:mm:ss') }}</vs-td>
                <vs-td>{{notify.is_active ? 'Có' : 'Không'}}</vs-td>
                <vs-td>
                    <vs-button @click="$emit('delete',notify)">Xóa</vs-button>
                </vs-td>
            </vs-tr>
        </template>
    </vs-table>
</template>
<script>
export default {
    name: "notifications",
    props: ["notifications"],
    data() {
        return {};
    },
};
</script>
