<template>
    <div class="w-full">
        <vs-button to="/admin/notifications/create">Tạo mới</vs-button>
        <notification-contianer />
    </div>
</template>
<script>
import NotificationContianer from "../../components/containers/notify/NotificationContianer.vue";
export default {
    components: { NotificationContianer },
    data() {
        return {};
    },
};
</script>